type Config = {
  apiRoot: string;
};
const PASSCODE = process.env.REACT_APP_DATA_PASSCODE;
const ENV_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;

function getConfig(): Config {
  const env = ENV_NAME ? ENV_NAME : 'tst';
  let apiRoot;
  if (env != 'prd') {
    apiRoot = `https://api.quality.gtn-geo-${env}.safeguardglobal.com`;
  } else {
    apiRoot = 'https://api.quality.gtn-geo.safeguardglobal.com';
  }
  return {
    apiRoot,
  };
}

export { PASSCODE, getConfig };
