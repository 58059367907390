export default function Report(props: any) {
  function errorSize(data: any) {
    return (
      (data['invalid_names'] as Array<any>).length +
      (data['missing_ids'] as Array<any>).length +
      (data['customer_invalid'] as Array<any>).length +
      (data['other_invalid'] as Array<any>).length
    );
  }

  function errorMessage(data: any) {
    const size =
      (data['invalid_names'] as Array<any>).length +
      (data['missing_ids'] as Array<any>).length +
      (data['customer_invalid'] as Array<any>).length +
      (data['other_invalid'] as Array<any>).length;
    return 'There are a total of ' + size + ' issues in the GTN file';
  }

  return (
    <div>
      {props.data &&
      props.data['column_mapping']['emp_id']['index'] !== null &&
      props.data['column_mapping']['emp_name']['index'] !== null &&
      props.data['column_mapping']['client']['index'] !== null &&
      errorSize(props.data) === 0 ? (
        <div className="flex justify-center mb-10">
          <div className="block rounded-lg shadow-lg bg-white max-w-sm text-center">
            <div className="py-3 px-6 font-bold border-b border-gray-300">
              Quality Check Report
            </div>
            <div className="p-6 text-left">
              <div>
                GTN file is quality checked, and it is considered valid based on
                the current checklist
              </div>
            </div>
            <div className="py-1 px-6 border-t bg-green-900 border-gray-300 text-white text-sm">
              <div className="inline-flex">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check-circle"
                  className="w-4 mr-2 fill-current"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                  ></path>
                </svg>
                Checks Done!
              </div>
            </div>
          </div>
        </div>
      ) : (
        props.data && (
          <div className="flex justify-center mb-10">
            {/* If any issues */}
            <div className="block rounded-lg shadow-lg bg-white max-w-sm text-center">
              <div className="py-3 px-6 font-bold border-b border-gray-300">
                Quality Check Report
              </div>
              <div className="p-6 text-left">
                {props.data && errorSize(props.data) > 0 && (
                  <div>{errorMessage(props.data)}</div>
                )}
                {props.data &&
                  props.data['column_mapping']['emp_id']['index'] === null && (
                    <div>Unable to identify the employee IDs of GTN file!</div>
                  )}
                {props.data &&
                  props.data['column_mapping']['emp_name']['index'] ===
                    null && (
                    <div>
                      Unable to identify the employee names of GTN file! The
                      expected name format is:{' '}
                      {'[name, (title.?) surname] or [surname, (title.?) name]'}
                    </div>
                  )}
                {props.data &&
                  props.data['column_mapping']['client']['index'] === null && (
                    <div>Unable to identify the clients of GTN file!</div>
                  )}
              </div>
              {props.data && errorSize(props.data) > 0 && (
                <div className="py-1 px-6 border-t bg-red-900 border-gray-300 text-white text-sm">
                  <div className="inline-flex">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="times-circle"
                      className="w-4 mr-2 fill-current"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                      ></path>
                    </svg>
                    GTN file has problems!
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
}
