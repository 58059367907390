import { getConfig } from '../config';
import axios from 'axios';

const config = getConfig();
const uploadApiUrl = `${config.apiRoot}/uploads`;
const qualityApiUrl = `${config.apiRoot}/inference`;

function toUrl(path: string, op: string, contentType?: string) {
  let url = `${uploadApiUrl}?key=${path}&operation=${op}`;
  if (op === 'put') {
    url += `&contentType=${contentType}`;
  }
  return url;
}

async function getPresignedUrl(url: string, opts?: any) {
  const res = await fetch(url, opts);
  const data = await res.json();
  return data.presignedURL;
}

export async function getS3(fileName: string): Promise<any> {
  try {
    const url = toUrl(fileName, 'get');
    const s3url = await getPresignedUrl(url);
    const res = await fetch(s3url);
    const data = await res.blob();
    return {
      data,
      contentType: res.headers.get('content-type'),
    };
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function uploadS3(
  fileCat: string,
  fileName: string,
  data: any,
  contentType: string,
): Promise<any> {
  try {
    // const url = toUrl(fileName, 'put', contentType);
    // const s3url = await getPresignedUrl(url);
    // const res = await fetch(s3url, {
    //   method: 'PUT',
    //   body: data,
    // });

    const finalUrl =
      uploadApiUrl +
      '?contentType=' +
      contentType +
      '&fileCat=' +
      fileCat +
      '&fileName=' +
      fileName;
    console.log('final url: ', finalUrl);

    // Get the presigned URL
    const response = await axios({
      method: 'GET',
      url: finalUrl,
    });

    console.log('Response: ', response);
    // let binary = atob(data);
    // let array = [];
    // for (var i = 0; i < binary.length; i++) {
    //   array.push(binary.charCodeAt(i));
    // }
    // let blobData = new Blob([new Uint8Array(array)], { type: contentType });
    console.log('Uploading to: ', response.data.presignedURL);
    const result = await fetch(response.data.presignedURL, {
      method: 'PUT',
      body: data,
    });
    console.log('Result: ', result);
    // Final URL for the user doesn't need the query string params
    const uploadURL = response.data.presignedURL.split('?')[0];
    console.log(uploadURL);

    // return res.ok;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function checkQuality(
  sfFileName: string,
  gtnFileName: string,
): Promise<any> {
  try {
    const baseUrl = `${qualityApiUrl}?`;
    const finalUrl =
      baseUrl + 'gtn_file=' + gtnFileName + '&salesforce_file=' + sfFileName;
    console.log('final url: ', finalUrl);
    const response = await axios({
      method: 'GET',
      url: finalUrl,
    });
    console.log('Response: ', response);
    return response;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}
