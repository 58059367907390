export default function Table(props: any) {
  const keys = Object.keys(props.data[0]);
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto items-center text-xs">
        <div className="py-2 inline-block min-w-full">
          <div className="overflow-hidden">
            <table className="w-5/6 border border-gray-200">
              <thead className="bg-slate-100 border-b border-gray-300">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-bold text-gray-900 px-4 py-4 text-left"
                  >
                    #
                  </th>
                  {keys.map(key => (
                    <th
                      scope="col"
                      className={`text-sm font-bold ${
                        key == 'Transformed Employee Name (GTN)'
                          ? 'text-gray-500'
                          : 'text-gray-900'
                      } px-4 py-4 text-left`}
                      key={key}
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.data.map((item: any, index: number) => (
                  <tr
                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                    key={index}
                  >
                    <td
                      className="text-gray-900 px-4 py-2 whitespace-nowrap"
                      key={index}
                    >
                      {index + 1}
                    </td>
                    {keys.map(key =>
                      item[key] instanceof Array ? (
                        <td
                          className="text-sm text-gray-900 px-4 py-2 whitespace-nowrap"
                          key={key}
                        >
                          {item[key][0]} - {item[key][1]}
                        </td>
                      ) : (
                        <td
                          className={`text-sm ${
                            key == 'Transformed Employee Name (GTN)'
                              ? 'text-gray-500'
                              : 'text-gray-900'
                          } px-4 py-2 whitespace-nowrap`}
                          key={key}
                        >
                          {item[key]}
                        </td>
                      ),
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="text-sm text-gray-700 mt-3 mb-5 inline-flex">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="exclamation-triangle"
          className="w-4 h-4 mr-2 fill-current text-red-500"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          ></path>
        </svg>
        There are{' '}
        <strong className="text-red-500 px-1">{props.data.length}</strong>
        issues in this section
      </div>
    </div>
  );
}
