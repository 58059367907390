import React, { useState } from 'react';
import Form from './components/form';
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// import { Home } from './home';
// import { Error } from './home/error';
import { PASSCODE } from './config';

export function App() {
  // COMMENTED TO AVOID PROBLEM ON BUILD PROCESS
  //   function qualityCheck(event: any) {
  //     event.preventDefault();
  //
  //     // request to lambda
  //   }
  const [passcode, setPasscode] = useState('');

  return (
    <>
      <div className="min-h-screen bg-gray-100 shadow rounded-lg">
        <nav className="relative w-full flex flex-wrap items-center justify-between py-4 bg-cyan-900 text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg">
          <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
            <div className="container-fluid">
              <a
                className="flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 mt-2 lg:mt-0 mr-1"
                href="#"
              >
                <img
                  className="mr-2"
                  src="https://mldzfkftr9aq.i.optimole.com/vMC8n7g-bMTiBH2T/w:auto/h:auto/q:mauto/https://www.safeguardglobal.com/wp-content/uploads/2019/04/safe-guard-global-white-logo.png"
                  alt=""
                  style={{ height: '32px' }}
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </nav>
        <div className="">
          <header>
            <div className="py-6 px-4 sm:px-6">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                Data Quality Check for GEO Invoicing
              </h1>
              <p className="mt-2 max-w-2xl text-sm text-gray-700">
                This tool automatically checks for discrepancies between the
                Salesforce file and your GTN file
              </p>
            </div>
          </header>
          {passcode !== PASSCODE ? (
            <form
              action="#"
              className="bg-gray-200 mt-10 p-10 mx-auto max-w-lg text-center shadow sm:rounded-lg"
            >
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt="Safeguard Global"
                />
                <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
                  Sign in to the
                  <br /> invoicing quality check
                </h2>
                <p className="mt-10 mb-3 text-center font-medium text-gray-600">
                  Passcode
                </p>
              </div>
              <label htmlFor="passcode" className="sr-only">
                Passcode...
              </label>
              <input
                type="password"
                name="passcode"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Enter the passcode"
                onChange={async e => {
                  setPasscode(e.target.value.toString());
                }}
              />
            </form>
          ) : null}
          {passcode === PASSCODE ? (
            <main className="px-4 py-5 sm:p-6">
              <Form />
            </main>
          ) : null}
        </div>
      </div>
    </>
    // <Router>
    //   <div>
    //     <nav>
    //       <ul>
    //         <li>
    //           <Link to="/">Home</Link>
    //         </li>
    //         <li>
    //           <Link to="/adjustment">Adjustment GL</Link>
    //         </li>
    //         <li>
    //           <Link to="/delta">Delta GL</Link>
    //         </li>
    //         <li>
    //           <Link to="/validation">Validation</Link>
    //         </li>
    //       </ul>
    //     </nav>

    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       <Route path="/admin" element={<Admin />} />
    //       <Route path="/adjustment" element={<AdjustmentGL />} />
    //       <Route path="/delta" element={<DeltaGL />} />
    //       <Route path="/validation" element={<Validation />} />
    //       <Route path="*" element={<Error />} />
    //     </Routes>
    //   </div>
    // </Router>>
  );
}

export default App;
